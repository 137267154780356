.signUpSection {
    min-height: 100vh;
    background: rgba(249, 250, 251, 1);
    background-image: url("../../Assets//Images/background/memberSignUp.png");
    background-size: 100% 50%;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcomeText {
    text-align: center;
}

.welcomeText h6 {
    font-family: "Inter";
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(29, 41, 57, 1);
}

.welcomeText p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(102, 112, 133, 1);
}

.welcomeText button {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 14px 18px;
    color: rgb(255, 255, 255);
    background: rgba(99, 102, 241, 1);
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    border: none;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.welcomeText button svg {
    margin-left: 8px;
}

/* tab panel */
.tabPanel {
    background-color: #fff;
    display: flex;
    width: 880px;
    overflow: hidden;
    padding: 40px 32px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.tabPanel .tabHeading {
    float: left;
    width: 30%;
    border-right: 1px solid rgba(234, 236, 240, 1);
}

.tabPanel .tabHeading ul {
    list-style: none;
    padding-left: 0px;
}

.tabPanel .tabHeading ul li {
    display: flex;
    margin-top: 20px;
    margin-bottom: 22px;
    cursor: pointer;
}

.arrowLine {
    margin-right: 12px;
}

.arrowLine .checkCircle {
    background-color: #fff;
}

.arrowLine .checkCircle::after {
    width: 2px;
    height: 42px;
    background-color: rgba(234, 236, 240, 1);
    position: absolute;
    top: 8px;
    position: relative;
    display: block;
    content: "";
    left: 11px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.selected .arrowLine .checkCircle::after {
    background-color: rgba(99, 102, 241, 1);
}

.arrowLine .checkCircle:last-child::after {
    background-color: rgb(255, 255, 255);
}

.arrowHeading h6 {
    margin-top: 6px;
    font-family: "Inter";
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 4px;
}

.arrowHeading p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
}

.selected .arrowHeading p,
.selected .arrowHeading h6 {
    color: rgba(80, 82, 201, 1);
}

.selected .arrowLine .checkCircle img {
    box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.tabContent {
    float: left;
    padding-left: 40px;
    width: 70%;
}

/* profile info */
.profileInfo h1,
.passwordInfo h1,
.billingInfo h1,
.agreementInfo h1 {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(16, 24, 40, 1);
}

.memberSignUp input {
    width: 100%;
}

.tabPanelBtn {
    margin-top: 40px;
    display: flex;
    justify-content: end;
}

.tabPanelBtn .continue {
    margin-left: 24px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 14px 18px;
    color: rgb(255, 255, 255);
    background: rgba(99, 102, 241, 1);
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    border: none;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.tabPanelBtn .continue svg,
.tabPanelBtn .skip svg {
    margin-left: 6px;
}

.tabPanelBtn .backBtn {
    width: 98px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 14px 18px;
    color: rgba(186, 199, 206, 1);
    background: rgb(255, 255, 255);
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    border: 1px solid rgba(186, 199, 206, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.tabPanelBtn .backBtn svg,
.tabPanelBtn .back svg {
    margin-right: 6px;
}

.tabPanelBtn .back,
.tabPanelBtn .skip {
    margin-left: 24px;
    width: 98px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 14px 18px;
    color: rgba(99, 102, 241, 1);
    background: rgb(255, 255, 255);
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    border: 1px solid rgba(99, 102, 241, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.memberImg {
    position: relative;
    margin-bottom: 15px;
}

.memberImg .member {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.round {
    position: absolute;
    bottom: -10px;
    left: 33px;
}

.agreementText {
    max-height: 230px;
    overflow-y: auto;
    padding: 12px 24px;
    margin-bottom: 25px;
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.agreementText h6 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.agreementText p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.agreeCheck .tableCheckBox .checkmark {
    height: 22px;
    width: 22px;
}

.agreeCheck .agreement {
    margin-top: 0px;
}

.agreeCheck .agreement .agreeText {
    margin-left: 10px;
}

.agreeCheck,
.signBox,
.signShowBox {
    padding: 18px 24px;
    margin-bottom: 25px;
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.signBox {
    text-align: center;
    cursor: pointer;
}

.signBox p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(105, 65, 198, 1);

}

.finishText {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.finishText img {
    width: 174px;
    height: 180px;
}

.finishText h6 {
    margin-top: 25px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(29, 41, 57, 1);
}

.finishText p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(52, 64, 84, 1);
}

.signatureTab h1 {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(16, 24, 40, 1);
}

.signatureBox {
    margin-top: 20px;
}

.signatureTab .nav-item a {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(102, 112, 133, 1);
}

.signatureTab .nav-item a.active {
    background: rgba(242, 244, 247, 1);
    color: rgba(52, 64, 84, 1);
}

.signaturePad p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.signaturePad .sigCanvas {
    width: 100%;
    height: 240px;
    border: 1px solid rgba(152, 162, 179, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.typeSignature {
    padding: 32px 16px;
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.typeSignature p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(152, 162, 179, 1);
}

.typeSignature input {
    border: none;
    border-bottom: 1.5px solid rgba(208, 213, 221, 1);
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
}

.signShowBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.signShow .sigImage {
    width: 120px;
    height: 72px;
    background-color: rgba(248, 250, 251, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.signShow .signatureName {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
    margin-top: 10px;
    display: block;
    min-width: 72px;
    padding: 8px 16px;
    background-color: rgba(248, 250, 251, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.signChange button {
    background-color: #fff;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(105, 65, 198, 1);
    border: none;
    margin: 0px 5px;
}

.passMatch {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgb(248, 50, 76);
}
.disable{
    margin-left: 24px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 14px 18px;
    color: rgb(255, 255, 255);
    background: rgba(186, 199, 206, 1);
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    border: none;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.StripeElement {
    align-items: center;
    background: #fff;
    border: 1px solid #d2d2d2;
    color: hsla(216,2%,46%,.8);
    display: grid;
    height: 50px;
    padding: 0 20px;
    position: relative;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.cardNumber{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.cardNumber label{
    font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #1F2937;
  margin-bottom: 7px;
}
.cardInput{
    width: 48%;
}