/* .invoiceView {
    padding: 26px 24px;
} */
.invoiceView {
    background-color: #fff;
    width: 80%;
    margin-right: 20px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.invoiceInformation {
    display: flex;
    margin-top: 25px;
}

.invoiceTop,
.invoiceInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 24px;
    border-bottom: 1px solid rgba(234, 236, 240, 1)
}

.invoiceInfo .invoiceId p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 4px;
}

.invoiceInfo .invoiceId h6{
font-family: "Inter";
font-size: 14px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(16, 24, 40, 1);
}

.invoiceTop .invoiceId h1 {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(102, 112, 133, 1);
}

.invoiceTop .invoiceId h1 span {
    font-weight: 400;
    color: rgba(29, 41, 57, 1);
}

.invoiceTop .invoiceDate p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(102, 112, 133, 1);
    margin-bottom: 0px;
}

.invoiceTop .invoiceDate p span {
    font-weight: 400;
}

.invoiceItem {
    padding: 26px 24px;
}

.invoiceItem .itemHeading {
    display: flex;
    justify-content: space-between;
}

.invoiceItem .itemHeading p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 0px;
}

.itemList {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 25px;
}

.itemList .itemName {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
}

.itemList .itemName img {
    margin-right: 10px;
}

.itemList .itemPrice {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
}

.itemTotal {
    display: flex;
    justify-content: end;
    border-top: 1px solid rgba(234, 236, 240, 1);
    padding-top: 24px;
}

.itemTotal p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.itemTotal p span {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    margin-left: 32px;
}

.invoiceMember {
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.invoiceMember h1 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 27px;
}

.invoiceMember .memberDetails {
    display: flex;
    align-items: center;
}

.invoiceMember .memberDetails p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    margin-bottom: 2px;
}

.invoiceMember .memberDetails span {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(152, 162, 179, 1);
}

.invoiceMember .memberDetails img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
}

.invoiceNotes {
    margin-top: 25px;
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.invoiceNotes h1 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 27px;
}

.invoiceNotes p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(152, 162, 179, 1);
}

.invoiceDownload {
    margin-top: 25px;
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.invoiceDownload p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 0px;
}

.invoiceDownload button {
    margin-top: 25px;
    border: none;
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    background: rgba(99, 102, 241, 1);
    width: 100%;
    padding: 12px 16px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    white-space: nowrap;
}

.invoiceId .status span{
    font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  width: auto;
  padding: 5px 10px;
  margin-bottom: 0px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.invoiceId .status .paid{
    color: rgba(2, 122, 72, 1);
  background: rgba(236, 253, 243, 1);
}