.settingPage {
    background-color: #fff;
    padding: 32px 32px 30px 32px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.companyOptions {
    display: flex;
    margin-bottom: 24px;
}

.companyOptions button {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    padding: 10px 16px;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

}

.companyOptions button.activeBtn {
    background-color: rgba(249, 250, 251, 1);
}

.companyOptions button:nth-child(1) {
    border-top: 1px solid rgba(208, 213, 221, 1);
    border-right: 1px solid rgba(208, 213, 221, 1);
    border-left: 1px solid rgba(208, 213, 221, 1);
    border-bottom: 1px solid rgba(208, 213, 221, 1);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.companyOptions button:nth-child(2),
.companyOptions button:nth-child(3),
.companyOptions button:nth-child(4),
.companyOptions button:nth-child(5) {
    border-top: 1px solid rgba(208, 213, 221, 1);
    border-right: 1px solid rgba(208, 213, 221, 1);
    border-left: none;
    border-bottom: 1px solid rgba(208, 213, 221, 1);
}

.companyOptions button:nth-child(5) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.companyProfile {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.rightSideSetting {
    width: 750px;
}

.rightFileSetting {
    width: 750px;
    display: flex;
}

.profileHeading h6 {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
}

.profileHeading p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
}

.profileSave .cancel {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    padding: 12px 16px;
    width: 120px;
    margin-right: 8px;
    color: rgba(99, 102, 241, 1);
    border: 1px solid rgba(99, 102, 241, 1);
    background-color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.profileSave .save {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    padding: 12px 16px;
    width: 120px;
    margin-right: 8px;
    background-color: rgba(99, 102, 241, 1);
    border: 1px solid rgba(99, 102, 241, 1);
    color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.companyName {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.companyName p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.contactDetails {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.companyInput {
    position: relative;
}

.companyInput input {
    width: 100%;
    border: 1px solid #D0D5DD;
    color: #171A20;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
}

.companyInput.email input {
    padding-left: 75px;
}

.companyInput.phone input {
    padding-left: 150px;
}

.companyInput.facebook input {
    padding-left: 200px;
}

.companyInput.instagram input {
    padding-left: 201px;
}

.companyInput.linkedin input {
    padding-left: 258px;
}

.companyInput span {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    padding: 12px 12px 11px 12px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
    background: transparent;
    z-index: 999;
    border-right: 1px solid #D0D5DD;
}

.socialProfile {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
}

.logoImageBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 27px;
    margin-right: 24px;
    width: 230px;
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.logoImageBox .changeLogo,
.backgroundImageBox .changeLogo {
    margin-right: 15px;
    width: 152px;
    height: 50px;
}

.backgroundImageBox .changeBack {
    margin-right: 15px;
    width: 115px;
    height: 82px;
}

.backgroundImageBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 27px 10px 10px;
    margin-right: 24px;
    width: 196px;
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.settingCustom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0px;
    border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.dashboardInfos {
    display: flex;
    align-items: center;
}

.dashboardInfos img {
    width: 42px;
    height: 42px;
    margin-right: 16px;
}

.dashboardInfos p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    margin-bottom: 0px;
}

.dashboardInfos span {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}