.generateInvoice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 35px;
}

.authToggle {
    align-items: center;
    display: flex;
    margin-top: 8px
}

.authToggle p {
    color: #a0a0a0;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 0
}

.authToggle .switch {
    display: inline-block;
    height: 24px;
    margin: 0 7px;
    position: relative;
    width: 44px
}

.authToggle .switch input {
    height: 0;
    opacity: 0;
    width: 0
}

.authToggle .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s
}

.authToggle .slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 20px;
    left: 2px;
    position: absolute;
    transition: .4s;
    width: 20px
}

.authToggle input:checked+.slider {
    background-color: rgba(80, 82, 201, 1);
}

.authToggle input:focus+.slider {
    box-shadow: 0 0 1px rgba(80, 82, 201, 1);
}

.authToggle input:checked+.slider:before {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
}

.authToggle .slider.round {
    border-radius: 34px
}

.authToggle .slider.round:before {
    border-radius: 50%
}
.assignName{
    margin-top: 25px;
    padding: 24px;
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.generateInvoice .amount span {
    position: absolute;
    top: 0px;
}

.generateInvoice .amount input {
    padding-left: 25px !important;
}

.generateInvoice h5 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.generateInvoice h6 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.generateInvoice input {
    min-width: 280px;
}

.generateInvoice .amount a {
    margin-top: 4px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(99, 102, 241, 1);
    text-decoration: none;
    border-bottom: 2px solid rgba(99, 102, 241, 1);
}
.memberSpaces{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.memberSpaces .arrowIcon{
color: rgba(102, 112, 133, 1);
}