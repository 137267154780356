.logo-item,
.vector-icon4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 24.6px;
  height: 24.9px;
}

.logo-item {
  top: 18.5px;
  left: 2.6px;
  background-color: var(--color-khaki);
  width: 3.9px;
  height: 3.9px;
  transform: rotate(-0.49deg);
  transform-origin: 0 0;
}

.logo4 {
  width: 24.6px;
  position: relative;
  height: 24.9px;
}

.main-street1 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: var(--gray-400);
}

.text42 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chevron-selector-vertical-icon1 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.container3,
.label8,
.text-group {
  display: flex;
  justify-content: flex-start;
}

.text-group {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}

.container3,
.label8 {
  align-self: stretch;
}

.label8 {
  border-radius: var(--br-5xs);
  background-color: var(--text-3);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-xs);
}

.container3 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-base);
}

.helpdesk23 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 24px;
}

.div24 {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 19px;
  font-weight: 600;
}

.pills24 {
  border-radius: var(--br-981xl);
  background-color: var(--brand-brand-primary);
  height: 20px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-7xs);
  box-sizing: border-box;
  font-size: var(--caption-semibold-size);
  color: var(--bg-4);
}

.icon-stroke-chevron-down23 {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: contain;
  display: none;
}

.menu23 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-xs);
}

.pills27 {
  border-radius: var(--br-981xl);
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-7xs);
  box-sizing: border-box;
  font-size: var(--caption-semibold-size);
  color: var(--bg-4);
}

.menu29,
.pills27,
.pills30 {
  background-color: var(--brand-brand-primary);
  align-items: center;
}

.pills30 {
  border-radius: var(--br-981xl);
  height: 20px;
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-7xs);
  box-sizing: border-box;
  font-size: var(--caption-semibold-size);
}

.menu29 {
  align-self: stretch;
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-xs);
  color: var(--bg-4);
}

.icon-stroke-chevron-down30 {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: contain;
}

.content17,
.menu22,
.top2 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.content17 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.menu22,
.top2 {
  flex: 1;
}

.menu22 {
  display: flex;
  justify-content: flex-start;
  padding: 0 var(--padding-base);
  color: var(--gray-400);
}

.top2 {
  background-color: var(--text-2);
  box-shadow: 2px 6px 3px -1px rgba(6, 25, 56, 0.07);
  padding: var(--padding-21xl) 0 var(--padding-xl);
  gap: var(--gap-21xl);
}

.menu32,
.sidebar2,
.top2 {
  display: flex;
  justify-content: flex-start;
}

.menu32 {
  align-self: stretch;
  background-color: var(--typography-body);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-xs);
  color: var(--brand-divider);
}

.sidebar2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: 1078px;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-7);
}

.dashboard2 {
  position: relative;
  line-height: 32px;
  font-weight: 600;
}

.dashboard-frame {
  align-items: center;
}

.dashboard-frame,
.heading2,
.nav-item-button4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.heading2 {
  width: 581px;
  align-items: center;
  gap: var(--gap-5xl);
}

.nav-item-button4 {
  border-radius: var(--br-7xs);
  background-color: var(--bg-4);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-3xs);
}

.avatar-icon28 {
  width: 40px;
  border-radius: var(--br-181xl);
  height: 40px;
  object-fit: cover;
}

.dropdown2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.elviro-anasta2 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.content18,
.content19 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content19 {
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--text-xs-semibold-size);
}

.content18 {
  align-self: stretch;
  justify-content: space-between;
}

.container4,
.header-navigation2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.container4 {
  align-self: stretch;
  height: 72px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-13xl);
}

.header-navigation2 {
  position: absolute;
  top: 0;
  left: 280px;
  background-color: var(--bg-4);
  border-bottom: 1px solid var(--gray-200);
  width: 1160px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--text-xl-semibold-size);
}

.text43,
.text44 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}

.text44 {
  line-height: 18px;
  font-weight: 500;
}

.badge1,
.text-and-badge1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.badge1 {
  border-radius: var(--br-base);
  background-color: var(--gray-50);
  display: none;
  padding: var(--padding-11xs) var(--padding-5xs);
  mix-blend-mode: multiply;
  text-align: center;
  font-size: var(--text-xs-medium-size);
  color: var(--primary-7001);
}

.text-and-badge1 {
  align-self: stretch;
  display: flex;
  gap: var(--gap-5xs);
}

.supporting-text1 {
  width: 362px;
  position: relative;
  font-size: var(--text-xs-semibold-size);
  line-height: 20px;
  color: var(--gray-600);
  display: none;
}

.text-and-supporting-text1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
  font-size: var(--text-lg-semibold-size);
}

.label9,
.search-md-icon7 {
  position: relative;
  display: none;
}

.label9 {
  width: 95px;
  line-height: 24px;
  font-weight: 500;
}

.search-md-icon7 {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text45 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
}

.content21,
.input-with-label7,
.input7 {
  display: flex;
  justify-content: flex-start;
}

.content21 {
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.input-with-label7,
.input7 {
  align-self: stretch;
}

.input7 {
  border-radius: var(--br-xs);
  background-color: var(--secondary-50);
  box-shadow: var(--shadow-xs);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-3xs) var(--padding-base);
  gap: var(--gap-5xs);
  color: var(--gray-400);
}

.input-with-label7 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-7xs);
}

.hint-text7 {
  width: 320px;
  position: relative;
  line-height: 24px;
  color: var(--gray-600);
  display: none;
}

.input-field6 {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  color: var(--typography-body);
}

.button8 {
  border-radius: var(--br-xs);
  background-color: var(--bg-4);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--brand-divider);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-lg);
  gap: var(--gap-5xs);
  color: var(--gray-700);
}

.text47 {
  position: relative;
  line-height: 16px;
  font-weight: 600;
}

.button9 {
  display: none;
}

.button10,
.button11,
.button9 {
  border-radius: var(--br-xs);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-5xs);
}

.button10 {
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--brand-brand-primary);
  display: none;
}

.button11 {
  background-color: var(--brand-brand-primary);
  box-shadow: 0 6px 30px rgba(42, 55, 119, 0.06);
  display: flex;
  color: var(--bg-4);
}

.actions4,
.content20 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.actions4 {
  align-items: center;
  gap: var(--gap-xs);
  color: var(--brand-brand-primary);
}

.content20 {
  align-self: stretch;
  align-items: flex-start;
  padding: var(--padding-xl) var(--padding-5xl) 0;
  gap: var(--gap-base);
}

.divider-icon1 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}

.card-header {
  align-self: stretch;
  background-color: var(--bg-4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.checkbox-base4 {
  width: 20px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--bg-4);
  border: 1px solid var(--brand-divider);
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.checkbox3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text50 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}

.arrow-down-icon2 {
  display: none;
}

.arrow-down-icon2,
.arrow-up-icon2 {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.table-header-cell,
.table-header1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.table-header1 {
  display: none;
  gap: var(--gap-9xs);
}

.table-header-cell {
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  padding: var(--padding-lg) var(--padding-5xl);
  gap: var(--gap-xs);
  color: var(--gray-600);
}

.text51 {
  width: 63px;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: none;
}

.table-cell {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-xs);
}

.avatar-icon29 {
  width: 40px;
  border-radius: var(--br-181xl);
  height: 40px;
  object-fit: cover;
  display: none;
}

.text52 {
  width: 65px;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: none;
}

.supporting-text2,
.text57 {
  position: relative;
  line-height: 20px;
}

.text57 {
  font-weight: 500;
}

.supporting-text2 {
  color: var(--gray-600);
}

.column,
.table-cell6 {
  justify-content: flex-start;
}

.table-cell6 {
  width: 292px;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  display: none;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-xs);
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--gray-900);
}

.checkbox11 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.table-header-cell1,
.table-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}

.table-header-cell1 {
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-xs);
  color: var(--gray-600);
}

.featured-icon1 {
  width: 40px;
  position: relative;
  border-radius: var(--br-xl);
  height: 40px;
}

.column1,
.table-header-cell2 {
  display: flex;
  justify-content: flex-start;
}

.column1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  color: var(--gray-900);
}

.table-header-cell2 {
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 44px;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xs) var(--padding-5xl);
  text-align: left;
  color: var(--gray-600);
}

.dot-icon {
  width: 8px;
  position: relative;
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
}

.badge2,
.table-cell14 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.badge2 {
  border-radius: var(--br-base);
  background-color: var(--success-50);
  padding: var(--padding-11xs) var(--padding-5xs) var(--padding-11xs) var(--padding-7xs);
  gap: var(--gap-9xs);
  mix-blend-mode: multiply;
}

.table-cell14 {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  padding: var(--padding-base) var(--padding-5xl);
}

.column2,
.table-cell23 {
  width: 120px;
  display: none;
  justify-content: flex-start;
}

.table-cell23 {
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) var(--padding-5xl);
}

.column2 {
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  font-size: var(--text-xs-medium-size);
  color: var(--success-700);
}

.table-header-cell3 {
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-xs);
}

.text78 {
  position: relative;
  line-height: 24px;
}

.kateuntitleduicom {
  position: relative;
  line-height: 20px;
}

.table-cell30 {
  width: 224px;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-5xl);
}

.column3 {
  width: 186px;
  flex-direction: column;
  align-items: flex-start;
}

.column3,
.column4,
.table-header-cell5 {
  display: flex;
  justify-content: flex-start;
}

.column4 {
  width: 120px;
  flex-direction: column;
  align-items: flex-start;
}

.table-header-cell5 {
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--text-xs-semibold-size);
}

.avatar-icon36,
.avatar-icon37,
.avatar-icon41 {
  width: 24px;
  border-radius: var(--br-181xl);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.avatar-icon37 {
  margin-left: -4px;
}

.avatar-icon41 {
  display: none;
}

.text92 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 12px);
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  width: 24px;
}

.avatar2 {
  width: 28px;
  position: relative;
  border-radius: var(--br-181xl);
  background-color: var(--gray-100);
  border: 2px solid var(--bg-4);
  box-sizing: border-box;
  height: 28px;
  margin-left: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.avatars2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.avatar-add-button2,
.content23 {
  border-radius: var(--br-xs);
  box-sizing: border-box;
}

.content23 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
}

.avatar-add-button2 {
  width: 25px;
  position: relative;
  background-color: var(--bg-4);
  border: 1px dashed var(--brand-divider);
  height: 25px;
  display: none;
}

.avatar-group2,
.table-cell38,
.table-cell44 {
  flex-direction: row;
  justify-content: flex-start;
}

.avatar-group2 {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-5xs);
}

.table-cell38,
.table-cell44 {
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  align-items: center;
}

.table-cell38 {
  align-self: stretch;
  display: flex;
  padding: var(--padding-sm) var(--padding-5xl);
}

.table-cell44 {
  width: 176px;
  display: none;
  padding: var(--padding-base) var(--padding-5xl);
  text-align: left;
  font-size: var(--text-xs-semibold-size);
}

.badge12,
.badge13,
.column5 {
  display: flex;
  justify-content: flex-start;
}

.column5 {
  width: 180px;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  font-size: var(--text-xs-medium-size);
}

.badge12,
.badge13 {
  flex-direction: row;
}

.badge12 {
  border-radius: var(--br-base);
  background-color: var(--primary-50);
  align-items: center;
  padding: var(--padding-11xs) var(--padding-5xs);
  mix-blend-mode: multiply;
}

.badge13 {
  background-color: var(--blue-50);
  color: var(--blue-700);
}

.badge13,
.badge14,
.badge15 {
  border-radius: var(--br-base);
  align-items: center;
  padding: var(--padding-11xs) var(--padding-5xs);
  mix-blend-mode: multiply;
}

.badge14 {
  background-color: var(--indigo-50);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--indigo-700);
}

.badge15 {
  background-color: var(--pink-50);
  display: none;
  color: var(--pink-700);
}

.badge15,
.badge16,
.badge17,
.badges {
  flex-direction: row;
  justify-content: flex-start;
}

.badge16 {
  border-radius: var(--br-base);
  background-color: var(--orange-dark-50);
  display: none;
  align-items: center;
  padding: var(--padding-11xs) var(--padding-5xs);
  mix-blend-mode: multiply;
  color: var(--orange-700);
}

.badge17,
.badges {
  display: flex;
}

.badge17 {
  border-radius: var(--br-base);
  background-color: var(--gray-100);
  align-items: center;
  padding: var(--padding-11xs) var(--padding-5xs);
  mix-blend-mode: multiply;
  color: var(--gray-700);
}

.badges {
  align-items: flex-start;
  gap: var(--gap-9xs);
}

.column6,
.table-cell54 {
  width: 288px;
  display: none;
  justify-content: flex-start;
}

.table-cell54 {
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) var(--padding-5xl);
}

.column6 {
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  font-size: var(--text-xs-medium-size);
  color: var(--primary-700);
}

.button12,
.button13 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
}

.button12 {
  display: none;
}

.button13 {
  border: 1px solid var(--brand-brand-primary);
  display: flex;
}

.trash-02-icon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.button14,
.table-cell55 {
  display: flex;
  align-items: center;
}

.button14 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-3xs);
}

.table-cell55 {
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  padding: var(--padding-base);
  gap: var(--gap-9xs);
}

.content22,
.table-cell55,
.table-cell61 {
  flex-direction: row;
  justify-content: flex-start;
}

.table-cell61 {
  width: 116px;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  display: none;
  align-items: center;
  padding: var(--padding-base);
  gap: var(--gap-9xs);
}

.content22 {
  align-self: stretch;
  background-color: var(--bg-4);
  display: flex;
  align-items: flex-start;
  color: var(--gray-600);
}

.text161 {
  flex: 1;
  position: relative;
  line-height: 24px;
}

.input8 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--bg-4);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--brand-divider);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-xs);
  gap: var(--gap-5xs);
  color: var(--gray-400);
}

.input-field-group,
.input-field7,
.input8 {
  display: flex;
  justify-content: flex-start;
}

.input-field7 {
  width: 90px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-7xs);
}

.input-field-group {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-base);
}

.text162 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.pagination-button-group-base9 {
  background-color: var(--bg-4);
  border-right: 1px solid var(--brand-divider);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-base);
  gap: var(--gap-5xs);
  z-index: 8;
}

.pagination-button-group-base10,
.pagination-button-group-base11 {
  width: 40.5px;
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-3xs);
}

.pagination-button-group-base10 {
  background-color: var(--gray-50);
  border-right: 1px solid var(--brand-divider);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
  color: var(--typography-subheading);
}

.pagination-button-group-base11 {
  z-index: 6;
}

.pagination-button-group-base11,
.pagination-button-group-base12,
.pagination-button-group-base13 {
  background-color: var(--bg-4);
  border-right: 1px solid var(--brand-divider);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-button-group-base12 {
  width: 40.5px;
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-3xs);
  z-index: 5;
}

.pagination-button-group-base13 {
  z-index: 4;
}

.pagination-button-group-base13,
.pagination-button-group-base14,
.pagination-button-group-base15 {
  width: 40.5px;
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-3xs);
}

.pagination-button-group-base14 {
  background-color: var(--bg-4);
  border-right: 1px solid var(--brand-divider);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.pagination-button-group-base15 {
  z-index: 2;
}

.pagination-button-group-base15,
.pagination-button-group-base16,
.pagination-button-group-base17 {
  background-color: var(--bg-4);
  border-right: 1px solid var(--brand-divider);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-button-group-base16 {
  width: 40.5px;
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-3xs);
  z-index: 1;
}

.pagination-button-group-base17 {
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-base);
  gap: var(--gap-5xs);
  z-index: 0;
}

.pagination-button-group1 {
  border-radius: var(--br-xs);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--brand-divider);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.pagination {
  align-self: stretch;
  border-top: 1px solid var(--gray-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-xl) var(--padding-5xl);
  color: var(--typography-body);
}

.filesTableList {
  border-radius: var(--br-xs);
  background-color: var(--bg-4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--gray-900);
}

.file-2-parent .favorite {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: var(--br-5xl);
  -webkit-border-radius: var(--br-5xl);
  -moz-border-radius: var(--br-5xl);
  -ms-border-radius: var(--br-5xl);
  -o-border-radius: var(--br-5xl);
  display: flex;
  align-items: center;
  justify-content: center;
}

.membership-agreementpdf {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-2 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--error-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-xs);
  gap: var(--gap-xs);
}

.logopng {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.file-3,
.file-5 {
  flex: 1;
  border-radius: var(--br-xs);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-5xl) var(--padding-xs);
  gap: var(--gap-xs);
}

.file-5 {
  background-color: var(--primary-25);
  display: flex;
  justify-content: flex-start;
}

.file-3 {
  background-color: var(--gray-50);
}

.file-2-parent,
.file-3,
.file-4,
.files {
  display: flex;
  justify-content: flex-start;
}

.file-4 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--warning-50);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-5xl) var(--padding-xs);
  gap: var(--gap-xs);
}

.file-2-parent,
.files {
  align-items: flex-start;
}

.file-2-parent {
  flex-direction: row;
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--text-xs-semibold-size);
  color: var(--typography-body);
}

.files {
  border-radius: var(--br-xs);
  background-color: var(--bg-4);
  box-shadow: 0 23px 44px rgba(176, 183, 195, 0.14);
  flex-direction: column;
  padding: var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  font-size: var(--text-lg-semibold-size);
}

.files-admin {
  width: 100%;
  position: relative;
  background-color: var(--brand-background-card-light);
  height: 1078px;
  overflow: hidden;
  text-align: left;
  font-size: var(--text-xs-semibold-size);
  color: var(--typography-subheading);
  font-family: var(--text-md-regular);
}

.featured-icon6 {
  width: 48px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 48px;
}

/* files table */
.filesTable {
  background-color: #fff;
  margin-top: 32px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.filesList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 478px);
}

.filesList .table-responsive {
  width: 100%;
}

.filesList table tbody tr td {
  vertical-align: middle;
}

.filesList table thead tr th {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #475467;
  height: 56px;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #F4F7F8;
}

.filesList table tbody tr td:nth-child(1) {
  color: #101828;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

}

.filesList table tbody tr td:nth-child(2),
.filesList table tbody tr td:nth-child(3),
.filesList table tbody tr td:nth-child(4),
.filesList table tbody tr td:nth-child(5),
.filesList table tbody tr td:nth-child(6) {
  color: #475467;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
}

.filesList table thead tr th:nth-child(1),
.filesList table tbody tr td:nth-child(1) {
  width: 68px;
  text-align: center;
  vertical-align: middle;
}

.filesList table tbody tr td:nth-child(2) img{
  width: 40px;
  height: 40px;
  background-color: #FEF3F2;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
} 
.filesList table thead tr th:nth-child(3) {
  width: 186px;
}

.filesList table thead tr th:nth-child(4) {
  width: 120px;
}

.filesList table thead tr th:nth-child(5) {
  width: 180px;
}

.filesList table thead tr th:nth-child(6) {
  width: 160px;
}

.filesList table tbody .tableAction button {
  width: 40px;
  height: 40px;
  padding: 0px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.filesList table tbody .tableAction button:nth-child(2) {
  margin: 0px 2px;
}

.filesList table tbody .tableAction button:nth-child(1) {
  border: 1px solid #6366F1;
  color: #6366F1;
}

.filesList table tbody .tableAction button:nth-child(2):hover {
  border: 1px solid #6366F1;
  margin: 0px 2px;
}

.filesList table tbody .tableAction button:nth-child(3):hover {
  border: 1px solid #6366F1;
}

/* file upload */
.drop-file-input {
  position: relative;
  width: 100%;
  height: 104px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.drop-file-input__label {
  text-align: center;
  padding: 10px;
}

.drop-file-input__label p {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #475467;
}

.drop-file-input__label p span {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6941C6;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.uploadFileShow {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #5052C9;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.uploadFileShow .fileFormat {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FEF3F2;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.uploadFileShow .fileFormat img {
  width: 16px;
  height: 16px;
}

.uploadFileShow .fileName {
  width: 100%;
  text-align: left;
  padding: 0px 16px;
}

.uploadFileShow .fileName p {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
  margin-bottom: 5px;
}

.uploadFileShow .fileName span {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #475467;
}

.uploadFileShow .fileDelete{
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadFileShow .fileDelete:hover{
  cursor: pointer;
  border: 1px solid #c01048;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}


.fileSendInfo {
  margin-top: 32px;
}

.fileNameType label {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
  margin-bottom: 3px;
}

.fileNameType input {
  font-family: 'Inter', sans-serif;
  width: 100%;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px 0px #1018280D;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;

}

.fileNameType .form-control:focus {
  color: none !important;
  background-color: none !important;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.sharing {
  margin-top: 16px;
}

.sharing p {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
  margin-top: 3px;
  margin-bottom: 12px;
}

.adminOption {
  display: flex;
}

.adminOption img {
  width: 40px;
  height: 40px;
  border: 2px solid #FFAB00;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.adminName {
  margin-left: 16px;
}

.adminName p {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #1D2939;
  margin-bottom: 3px;
  line-height: 12px;
}

.adminName span {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #98A2B3;
}

.shareMember .content p {
  font-size: 15px;
}

.shareMember .content ul {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  box-shadow: 0px 1px 2px 0px #1018280D;
  border: 1px solid #D0D5DD;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.shareMember .content ul li {
  color: #333;
  height: 24px;
  margin-right: 5px;
  list-style: none;
  background: #F2F2F2;
  padding: 0px 6px;
  border: 1px solid #e3e1e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #FFAB00;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.shareMember .content ul li img {
  width: 16px;
  height: 16px;
  border: 1px solid #FFAB00;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.shareMember .content ul li span {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: #344054;
  margin: 0px 5px;
}

.shareMember .content ul li svg {
  color: #98A2B3;
  font-size: 13px;
  cursor: pointer;
}

.shareMember .content ul input {
  flex: 1;
  padding: 5px;
  border: none;
  outline: none;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #98A2B3;

}

.uploadBtn {
  display: flex;
  justify-content: end;
}

.uploadBtn .save,.uploadBtn .save:hover {
  margin-top: 24px;
  background: #6366F1;
  color: #fff;
  box-shadow: 0px 2.4000000953674316px 6px 0px #0619380F;
  padding: 12px 50px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.uploadBtn .noFile,.uploadBtn .noFile:hover {
  margin-top: 24px;
  background: #BAC7CE;
  color: #fff;
  box-shadow: 0px 2.4000000953674316px 6px 0px #0619380F;
  padding: 12px 50px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.searchMemberList{
  display: flex;
  padding-left: 0px;
}
.searchMemberList li{
  color: #333;
  height: 24px;
  margin-right: 5px;
  list-style: none;
  background: #F2F2F2;
  padding: 0px 6px;
  border: 1px solid #e3e1e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #FFAB00;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  cursor: pointer;
}
.searchMemberList li img {
  width: 16px;
  height: 16px;
  border: 1px solid #FFAB00;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.searchMemberList li span {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: #344054;
  margin: 0px 5px;
}

.tableAction .assignBtn{
  width: 79px !important;
  height: 40px !important;
  border: 1px solid #6366F1;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #475467;
}

.favoriteBox{
  width: 158px;
}