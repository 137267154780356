.sideBar {
    background: #1F2937;
    height: 100vh;
}

/* navbar */
.topNavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0px 32px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #EAECF0, #EAECF0);
    border-bottom: 1px solid #EAECF0
}

.contentHeading {
    display: flex;
}

.contentHeading button {
    background-color: transparent;
    color: #667085;
    font-size: 18px;
    border: none;
}

.contentHeading p {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D2939;
    margin-bottom: 0px;
    margin-left: 20px;
}

.rightNavbar {
    display: flex;
    align-items: center;
}
.rightNavbar .memberImg img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.rightNavbar button {
    background-color: transparent;
    margin: 0px 5px;
    border: none;
}

.rightNavbar .dropdown button {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D2939;
}

.rightNavbar .dropdown button:hover {
    background-color: #fff;
}

.rightNavbar .dropdown-toggle::after {
    display: none !important;
}

.rightNavbar .dropdown-toggle img {
    margin-left: 10px;
}

/* navbar */

/* sidebar */
.sidebarBox {
    height: 100%;
    overflow: hidden auto;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 101;
    background-color: #1F2937;
}

.sidebarLogo {
    margin-top: 40px;
    background: #374151;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.sidebarLogo img {
    width: 23px;
    height: 23px;
}

.sidebarLogo .arrow {
    width: 10px;
    height: 16px;
}

.sidebarLogo .adminHeading {
    width: 100%;
    padding-left: 8px;
}

.sidebarLogo .adminHeading p {
    color: #D1D5DB;
    margin-bottom: 0px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.sidebarLogo .adminHeading span {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #98A2B3;
}

/* sidebar menu */
.sidebarMenu {
    background-color: #1F2937;
    height: calc(100vh - 165px);
    overflow-y: auto;
    padding-top: 40px;
    padding-bottom: 15px;
}

.sidebarMenu ul .navLink {
    width: 100%;
    background: transparent;
    padding: 12px 16px;
}

.sidebarMenu ul .navLink a svg,
.sidebarMenu ul .navLink a img,
.sidebarMenu ul .dropNavLink button svg,
.sidebarMenu ul .dropNavLink button img,
.sidebarFooter ul .dropNavLink button svg {
    margin-right: 12px;
}

.sidebarMenu ul .navLink a,
.sidebarMenu ul .dropNavLink button,
.sidebarFooter ul .dropNavLink button {
    color: #98A2B3;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
    width: 100%;
    cursor: pointer;
    display: block;
}

.sidebarMenu ul .dropNavLink #home-collapse,
.sidebarMenu ul .dropNavLink #message-collapse {
    background-color: #fff;
}

.sidebarMenu ul .dropNavLink #home-collapse ul li,
.sidebarMenu ul .dropNavLink #message-collapse ul li {
    background-color: #1F2937;
    padding: 11px 2px 9px 23px;
}

.sidebarMenu ul .dropNavLink #home-collapse ul li a,
.sidebarMenu ul .dropNavLink #message-collapse ul li a,
.sidebarFooter ul .dropNavLink #help-collapse ul li button {
    color: #667085;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
    width: 100%;
    cursor: pointer;
    padding: 12px 16px;
    background-color: #ffffff00;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding-left: 32px;
}
.activeDropdownMenu a{
    background-color: #6366F1 !important;
    width: 100% !important;
    display: inline-block !important;
    color: #FFFFFF !important;
}

.sidebarMenu ul .dropNavLink .btn-toggle,
.sidebarFooter ul .dropNavLink .btn-toggle {
    width: 100%;
    background: transparent;
    padding: 12px 16px;
    text-align: left;
    position: relative;
}

.dropNavLink .btn-toggle:before {
    position: absolute;
    right: 10px;
    top: 19px;
    width: 1.25em;
    display: inline-flex;
    line-height: 0;
    content: url("../../Assets/Images/icon/rightArrow.png");
    transform-origin: center;
    transition: transform .35s ease;
    -webkit-transition: transform .35s ease;
    -moz-transition: transform .35s ease;
    -ms-transition: transform .35s ease;
    -o-transition: transform .35s ease;
}

.dropNavLink .btn-toggle[aria-expanded="true"]:before {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.sidebarFooter {
    background-color: #344054;
}

.sidebarFooter ul {
    margin-bottom: 0px;
    list-style-type: none;
    padding-left: 0px;
}

.sidebarFooter ul .navLink {
    width: 100%;
    background: transparent;
    padding: 12px 16px;
}

/* .sidebarFooter ul .dropNavLink button{
    color: #FFFFFF;
} */
.sidebarMenu ul .navLink.activeMenu {
    width: 100%;
    background: #6366F1;
    color: #FFFFFF !important;
    padding: 12px 16px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.sidebarMenu ul .navLink.activeMenu a {
    color: #FFFFFF !important;
}

/* sidebar */