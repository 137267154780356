.createInvoice {
    background-color: #fff;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.invoiceForm {
    padding: 26px 24px;
}

.invoiceInput {
    position: relative;
}

.invoiceInput .dropdown button {
    width: 100%;
    border: 1px solid #D0D5DD;
    color: #171A20;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 10px 12px 10px 12px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 99;
}

.invoiceInput label {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1F2937;
    margin-bottom: 7px;
    margin-top: 15px;
}

.invoiceInput svg {
    position: absolute;
    right: 0px;
    bottom: 4px;
    padding: 12px 12px 11px 12px;
    z-index: 1;
}

.invoiceBtn {
    margin-top: 15px;
    background: rgba(186, 199, 206, 1);
    box-shadow: 0px 6px 30px 0px rgba(218, 228, 233, 0.16);
    padding: 14px 16px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.invoiceBtn.active {
    margin-top: 15px;
    background: #6366F1;
    box-shadow: 0px 6px 30px 0px rgba(218, 228, 233, 0.16);
    padding: 14px 16px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.dueDateFormat {
    align-items: center;
    display: flex;
}

.dueDateFormat .react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    display: inline-block;
    position: relative;
    width: 100%;
}

.dueDateFormat .calenderBox {
    height: 47px;
}

.calenderBox,
.calenderShowBox {
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    color: #747679;
    display: flex;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    background: #fff;
    border: 1px solid #d2d2d2;
    padding: 9px 20px;
    width: 100%;
}

.dueDateFormat p {
    color: #747679;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    position: absolute;
    z-index: 99;
}

.calenderBox img {
    position: absolute;
    right: 20px;
}

.calenderInput label{
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1F2937;
    margin-bottom: 7px;
    margin-top: 15px;
}

.react-datepicker-popper{
    line-height: 0;
    z-index: 99999 !important;
}